document.addEventListener('DOMContentLoaded', function () {
    iniciarApp();
})

function iniciarApp() {
    navegacionFija();
    crearGaleria();
    scrollNav();
}

function navegacionFija() {
    const navegacion = document.querySelector('.header');
    const intro = document.querySelector('.intro');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function () {
        if (intro.getBoundingClientRect().top < 0 ) {
            navegacion.classList.add('fijo');
            body.classList.add('nav-fix');
        }else{
            navegacion.classList.remove('fijo');
            body.classList.remove('nav-fix');
        }
    })
}

function scrollNav() {
    const enlaces = document.querySelectorAll('.navegacion-principal .scrll-a');
    enlaces.forEach(enlace => {
        enlace.addEventListener('click', function  (e) {
            e.preventDefault();
            const seccionScroll = e.target.attributes.href.value;
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({behavior: "smooth"});
        })
    });
}

function crearGaleria() {
    const galeria = document.querySelector('.galeria-fotografica');

    for (let i = 1; i <= 10; i++){
        const imagen = document.createElement('picture');
        imagen.innerHTML = `
            <source srcset="build/img/thumb/${i}.avif" type="image/avif">
            <source srcset="build/img/thumb/${i}.webp" type="image/webp">
            <img loading="lazy" width="200" height="300" src="build/img/thumb/${i}.avif" alt="Posgrado Fitotecnia Galería">
        `;
        imagen.onclick = function() {
            mostrarImagen(i);
        }
        
        galeria.appendChild(imagen);
    }
}

function mostrarImagen(id) {
    const imagen = document.createElement('picture');
    imagen.innerHTML = `
        <source srcset="build/img/big/${id}.avif" type="image/avif">
        <source srcset="build/img/big/${id}.webp" type="image/webp">
        <img loading="lazy" width="200" height="300" src="build/img/big/${id}.avif" alt="Posgrado Fitotecnia Imagen de la Galería">
    `;
    // creamos el overlay y la imagen
    const overlay = document.createElement('DIV');
    overlay.appendChild(imagen);
    overlay.classList.add('overlay');
    overlay.onclick = function () {
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }

    // boton de cerrar
    const cerrarModal = document.createElement('P');
    cerrarModal.textContent= 'cerrar';
    cerrarModal.classList.add('btn-cerrar');
    cerrarModal.onclick = function () {
        const body = document.querySelector('body');
        body.classList.remove('fijar-body');
        overlay.remove();
    }
   overlay.appendChild(cerrarModal);

    // agregas al body completo
    const body = document.querySelector('body');
    body.appendChild(overlay);
    body.classList.add('fijar-body');
}